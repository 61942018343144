import React from 'react'
import './help-center.scss'
import { H2, H3, H4, P, Input, SearchIcon, Button, Anchor, H5 } from '../../lib/components'
import { getFormattedDate, truncateString } from '../../lib/utils/helpers'
import { toPlainText } from '@portabletext/react'
import {
  HelpCenterPresenterProps,
  HelpFeaturedArticle,
  HelpArticleSearchResult,
} from '../../lib/types'

const HelpCenterPresenter = ({
  searchText,
  handleSearchChange,
  handleSearch,
  featuredArticles,
  searchResults,
  apiResponse,
}: HelpCenterPresenterProps) => {
  return (
    <div className='help-center-main-layout'>
      {/* Page title */}
      <H2
        text="Got questions? We've got answers."
        classes={['help-center-main-layout__heading', 'mt-40', 'mb-48']}
      />

      {/* Search bar */}
      <div className='help-center-main-layout__search-input'>
        <Input
          name='keyword'
          value={searchText}
          handleChange={handleSearchChange}
          placeholder='Search'
          keyToTrigger='Enter'
          handleKeyPress={handleSearch}
        />
        <Button
          handleClick={handleSearch}
          classes={['search-bar-btn']}
          variant='secondary-btn'
          IconComponent={
            <span className='help-center-main-layout__search-input-icon'>
              <SearchIcon />
            </span>
          }
          iconVariant='right'
          text=''
        />
      </div>

      {apiResponse?.type === '' ? (
        /* Featured Articles section */
        <div className='help-center-main-layout__featured-articles'>
          <H3 text='Featured Articles' classes={['help-center-main-layout__heading', 'mt-40']} />
          <P text='Learn more about Credibila' classes={['center', 'mt-8']} />
          <div className='help-center-main-layout__featured-articles-container mt-72'>
            {featuredArticles?.map(
              (
                { title, content, createdAt, slug, shortSummary }: HelpFeaturedArticle,
                idx: number,
              ) => (
                <div key={idx} className='help-center-main-layout__featured-articles-item'>
                  <Anchor
                    variant='basic-anchor'
                    text={title}
                    href={slug}
                    classes={['help-center-main-layout__featured-articles-item-heading']}
                  />
                  <P
                    text={getFormattedDate(createdAt, 'DD/MM/YYYY')}
                    classes={['left', 'mt-8', 'default-gray']}
                  />
                  <P
                    text={truncateString(toPlainText(shortSummary ?? content), 155)}
                    classes={['left', 'mt-8', 'default-gray']}
                  />
                </div>
              ),
            )}
          </div>
        </div>
      ) : (
        /* Search Results section */
        <div className='help-center-main-layout__search-results'>
          <H3
            text='Search Results'
            classes={['help-center-main-layout__search-results-heading', 'mt-40']}
          />
          <P
            text={`${searchResults.length} ${
              searchResults.length === 1 ? 'result' : 'results'
            } found for '${apiResponse.searchString}'`}
            classes={['left', 'mt-8', 'default-gray']}
          />
          <div className='help-center-main-layout__search-results-container mt-72'>
            {searchResults?.map(
              ({ title, content, slug, shortSummary }: HelpArticleSearchResult, idx: number) => (
                <div key={idx} className='help-center-main-layout__search-results-item mb-20'>
                  <Anchor
                    variant='basic-anchor'
                    text={title}
                    href={slug}
                    classes={['help-center-main-layout__search-results-item-heading']}
                  />
                  <P
                    text={truncateString(toPlainText(shortSummary ?? content), 255)}
                    classes={['left', 'mt-12', 'mb-16']}
                  />
                  <Anchor
                    classes={['learn-more']}
                    variant='basic-anchor'
                    text='Learn More'
                    href={slug}
                  />
                </div>
              ),
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export { HelpCenterPresenter }
