/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  InvestmentCard,
  SearchIcon,
  Input,
  SelectDropdown,
  Button,
  H3,
  H4,
} from '../../lib/components'
import { DashboardPortfolioSummary } from './dashboard-portfolio-summary'
import { useBreakpoint } from '../../lib/utils/hooks'
import { ActiveInvestments } from './active-investments'
import { ClosedPortfolio } from './closed-portfolio'
import { WatchlistPortfolio } from './watchlist-portfolio'

const DashboardPresenter = ({
  email,
  name,
  kycStatus,
  userInvestmentDetail,
  investments,
  expectedIRR,
  totalInvestments,
  handleSearchParams,
  searchParams,
  resetSearchParams,
  currentPage,
  pagination,
  handleCurrentPage,
  quarterlyReport,
  handleFilePreview,
  handleStepperForInvestment,
}: any) => {
  const navigate = useNavigate()
  const { breakpoint } = useBreakpoint()
  const summaryProps: any = {
    activeInvestments: 2,
    pastInvestments: 0,
    watchListCount: 3,
    totalInvestmentAmount: 13444000,
    returnsAmount: 2340000,
  }
  const graphValues = [
    { date: '2023-01-01', investment: 1000, return: 1200 },
    { date: '2023-02-01', investment: 1100, return: 1150 },
    { date: '2023-03-01', investment: 1500, return: 1800 },
    { date: '2023-04-01', investment: 1600, return: 1900 },
    { date: '2023-05-01', investment: 1400, return: 1600 },
    { date: '2023-06-01', investment: 1800, return: 2200 },
    { date: '2023-07-01', investment: 1900, return: 2100 },
    { date: '2023-08-01', investment: 2000, return: 2500 },
    { date: '2023-09-01', investment: 1700, return: 2300 },
    { date: '2023-10-01', investment: 2200, return: 2800 },
    { date: '2023-11-01', investment: 2300, return: 2900 },
    { date: '2023-12-01', investment: 2400, return: 3100 },
    { date: '2024-01-01', investment: 2500, return: 3200 },
    { date: '2024-02-01', investment: 2600, return: 3400 },
    { date: '2024-03-01', investment: 3000, return: 3500 },
    { date: '2024-04-01', investment: 2800, return: 3700 },
    { date: '2024-05-01', investment: 2900, return: 3800 },
    { date: '2024-06-01', investment: 3000, return: 4000 },
    { date: '2024-07-01', investment: 3100, return: 4100 },
    { date: '2024-08-01', investment: 3200, return: 4300 },
    { date: '2024-09-01', investment: 3300, return: 4400 },
    { date: '2024-10-01', investment: 3400, return: 4600 },
    { date: '2024-11-01', investment: 3500, return: 4700 },
    { date: '2024-12-01', investment: 3600, return: 4900 },
    { date: '2025-01-01', investment: 3700, return: 5000 },
    { date: '2025-02-01', investment: 3800, return: 5200 },
    { date: '2025-03-01', investment: 3900, return: 5200 }, // Example of no returns
    { date: '2025-04-01', investment: 3900, return: 5200 }, // Example of missing data
    { date: '2025-05-01', investment: 3900, return: 5200 }, // Example of missing data
    { date: '2025 -06 -01', investment: 4200, return: 5800 }, // Example of high values
    { date: '2025 -07 -01', investment: 4300, return: 5900 },
  ]
  const imageURL =
    'https://scontent.fdel10-1.fna.fbcdn.net/v/t39.30808-6/367466249_807629821366663_4202439148089773740_n.jpg?_nc_cat=106&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=wHBCDJzV4xYQ7kNvgEfLzOO&_nc_zt=23&_nc_ht=scontent.fdel10-1.fna&_nc_gid=AuznFraFSkgGSfYoH2rnX7W&oh=00_AYAMeP-pX4thgw5H8QJqVBTDakOZgXFqBliNZUyAqqoUgw&oe=6738E3F3'
  const activeInvestments: any = {
    averageReturn: '16%',
    currentInvestmentValue: '$150K',
    investments: [
      {
        opportunity_title: 'Alpha High Seas Trade Finance',
        cover_image: imageURL,
        values: [...graphValues],
      },
      {
        opportunity_title: 'Alpha High Seas Trade Finance',
        cover_image: imageURL,
        values: [...graphValues],
      },
      {
        opportunity_title: 'Alpha High Seas Trade Finance',
        cover_image: imageURL,
        values: [...graphValues],
      },
    ],
  }

  const closedPortfolio: any = {
    averageReturn: 'N/A',
    currentInvestmentValue: 'N/A',
    investments: [
      // {
      //   opportunityName: 'Alpha',
      //   coverImage: '',
      //   opportunitySlug: ""
      // },
    ],
  }

  const watchlistPortfolio: any = {
    projectedReturn: 'N/A',
    watchlistOpportunities: [
      {
        id: 14,
        cover_image:
          'https://gbc-dev-uploads.s3.ap-south-1.amazonaws.com/SUPERUSER/image/jpeg/c12d4520-72d3-43e9-8317-831aae811316.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3U7RQTM7X5VCP5RA%2F20241108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20241108T090009Z&X-Amz-Expires=900&X-Amz-Signature=46efcf81a776b9069eb27f78c6131d8d415b8eaedef1c74f0b6423b41460f0ff&X-Amz-SignedHeaders=host&response-content-disposition=attachment',
        opportunity_title: 'Updated Opportunity Title',
        opportunity_summary:
          'This is an updated summary of the investment opportunity. The experienced management team has a proven track record in the industry, and the projects are located in attractive markets with strong demand for sustainable living spaces. Our focus is on eco-friendly developments that not only provide financial returns but also contribute positively to the environment. We believe in the importance of sustainability and aim to create living spaces that are both beautiful and functional. Each project is carefully selected based on rigorous market analysis and feasibility studies. Investors can expect regular updates and transparent reporting throughout the investment period. Our commitment to excellence is reflected in our meticulous approach to project management. We prioritize building strong relationships with our investors and stakeholders. Join us in making a difference while achieving your financial goals. Together, we can create a sustainable future for generations to come.',
        opportunity_slug: 'updated-opportunity-slug',
        images: [
          'https://gbc-dev-uploads.s3.ap-south-1.amazonaws.com/https%3A//fastly.picsum.photos/id/0/5000/3333.jpg%3Fhmac%3D_j6ghY5fCfSD6tvtcV74zXivkJSPIfR9B8w34XeQmvU?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3U7RQTM7X5VCP5RA%2F20241108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20241108T081006Z&X-Amz-Expires=900&X-Amz-Signature=c082e7875b6e51753bb3d059bf75dd9ab288e3618e8d8ee198a936291dcef4b7&X-Amz-SignedHeaders=host&response-content-disposition=attachment',
          'https://gbc-dev-uploads.s3.ap-south-1.amazonaws.com/https%3A//fastly.picsum.photos/id/20/3670/2462.jpg%3Fhmac%3DCmQ0ln-k5ZqkdtLvVO23LjVAEabZQx2wOaT4pyeG10I?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3U7RQTM7X5VCP5RA%2F20241108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20241108T081006Z&X-Amz-Expires=900&X-Amz-Signature=5c51e0c0c02d65244b76f2c572362455751dd9218e1cec95458bc8ddea7b9948&X-Amz-SignedHeaders=host&response-content-disposition=attachment',
          'https://gbc-dev-uploads.s3.ap-south-1.amazonaws.com/https%3A//fastly.picsum.photos/id/26/4209/2769.jpg%3Fhmac%3DvcInmowFvPCyKGtV7Vfh7zWcA_Z0kStrPDW3ppP0iGI?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3U7RQTM7X5VCP5RA%2F20241108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20241108T081006Z&X-Amz-Expires=900&X-Amz-Signature=c6b19b0d5e01d4925d393861d159038caef92311c5ebaec5036271bb61e38d24&X-Amz-SignedHeaders=host&response-content-disposition=attachment',
          'https://gbc-dev-uploads.s3.ap-south-1.amazonaws.com/https%3A//fastly.picsum.photos/id/163/2000/1333.jpg%3Fhmac%3DhtdHeSJwlYOxS8b0TTpz2s8tD_QDlmsd3JHYa_HGrg8?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3U7RQTM7X5VCP5RA%2F20241108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20241108T081006Z&X-Amz-Expires=900&X-Amz-Signature=f9001e123bc7e72531785e148e9d09fa683e3c1fd0358339ce773cbec1759b7e&X-Amz-SignedHeaders=host&response-content-disposition=attachment',
          'https://gbc-dev-uploads.s3.ap-south-1.amazonaws.com/https%3A//gbc-credibila-assets.s3.amazonaws.com/temp/1000004942.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA3U7RQTM7X5VCP5RA%2F20241108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20241108T081006Z&X-Amz-Expires=900&X-Amz-Signature=f458d1db885ba3d3221c220bf30535dcc52f5ce5f0d8b9568967ee19bb4de313&X-Amz-SignedHeaders=host&response-content-disposition=attachment',
        ],
        opportunity_id: 2,
        investment_return: '12.50',
        asset_class: 'Real Estate',
        ticket_size: '75000.00',
        currency_code: 'USD',
        raise_goal: '3500000.00',
        short_opportunity_summary:
          'The investment opportunity focuses on eco-friendly developments in high-demand markets. Led by an experienced team, projects aim for both financial returns and environmental impact. Investors can expect transparency, regular updates.',
      },
    ],
  }

  return (
    <div className='dashboard-container'>
      <H4 text='Portfolio Summary' />

      {/* Portfolio Summary Section */}
      <DashboardPortfolioSummary {...summaryProps} />

      {/* Active Investments Section */}
      <ActiveInvestments
        averageReturn={activeInvestments.averageReturn}
        currentInvestmentValue={activeInvestments.currentInvestmentValue}
        investments={activeInvestments.investments}
      />

      {/* Closed Portfolio Section */}
      <ClosedPortfolio
        averageReturn={closedPortfolio.averageReturn}
        currentInvestmentValue={closedPortfolio.currentInvestmentValue}
        investments={closedPortfolio.investments}
      />

      {/* Watchlist Portfolio Section */}
      <WatchlistPortfolio
        projectedReturn={watchlistPortfolio.projectedReturn}
        watchlistOpportunities={watchlistPortfolio.watchlistOpportunities}
      />
    </div>
  )
}

export { DashboardPresenter }
