import React from 'react'
import { LineChart, Line, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { Button, Flex, H2, H5, P } from '../../lib/components'
import { displayDollar } from '../../lib/utils/helpers'

interface Props {
  data: any[]
}

interface InvestmentCardProps {
  opportunity_title: string
  cover_image: string
  values: { date: string; return: number; investment?: number }[]
}

const ChartCard = ({ data }: Props) => {
  return (
    <div className='dashboard-container__active-investments-section__investment-card__chart-card'>
      <P text={<strong>Current Investment Value*</strong>} textSize='small' />
      <H2 text={displayDollar('13444000', 'USD')} classes={['mt-4', 'mb-20']} />
      <ResponsiveContainer width='100%' height={250}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray='3 5' />
          <YAxis axisLine={false} />
          <Tooltip />
          <Line type='monotone' dataKey='return' stroke='#8884d8' activeDot={{ r: 8 }} name='' />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

const InvestmentCard = ({ opportunity_title, cover_image, values }: InvestmentCardProps) => {
  return (
    <div className='dashboard-container__active-investments-section__investment-card'>
      {/* Active opportunity image and name */}
      <Flex classes={['align-center', 'mb-24']}>
        <div className='dashboard-container__active-investments-section__investment-card__image'>
          <img src={cover_image} alt='opportunity cover' />
        </div>
        <Flex classes={['ml-16']}>
          <H5 text={opportunity_title} />
        </Flex>
      </Flex>

      {/* Active opportunity card */}
      <ChartCard data={values} />

      {/* Active opportunity button section */}
      <Flex
        classes={[
          'dashboard-container__active-investments-section__investment-card__button-section',
        ]}
      >
        <Button
          variant='primary-btn'
          text='View Details'
          handleClick={() => {
            return
          }}
        />
        <Button
          variant='basic-btn'
          text='Redeem'
          classes={['ml-8']}
          handleClick={() => {
            return
          }}
        />
      </Flex>
    </div>
  )
}

export { InvestmentCard }
