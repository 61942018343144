import React from 'react'
import SectionHeader from './section-header'
import { Span } from '../../lib/components'
import { WatchlistCard } from './watchlist-card'
import { trimText } from '../../lib/utils/helpers'

const WatchlistPortfolio = ({ projectedReturn, watchlistOpportunities }: any) => {
  return (
    <div className='dashboard-container__watchlist-section'>
      <SectionHeader
        sectionName='Watchlist Portfolio'
        sectionHelperValues={[{ name: 'Projected Avg Return', value: projectedReturn }]}
      />
      <Span text='' variant='thin'>
        You currently have {watchlistOpportunities.length} opportunities in your watchlist
      </Span>

      {watchlistOpportunities.length > 0 &&
        watchlistOpportunities?.map((opportunity: any) => {
          const {
            id,
            opportunity_id,
            opportunity_slug,
            asset_class,
            currency_code,
            amount,
            opportunity_title,
            opportunity_summary,
            short_opportunity_summary,
            investment_return,
            ticket_size,
            images,
            cover_image,
            raise_goal,
          } = opportunity
          return (
            <WatchlistCard
              key={id}
              opportunityCover={cover_image ?? images[0]}
              opportunityName={opportunity_title}
              opportunitySummary={trimText(short_opportunity_summary, 150)}
              opportunitySlug={opportunity_slug}
              opportunityId={opportunity_id}
              irr={investment_return}
              assetClass={asset_class}
              ticketSize={ticket_size}
              currencyCode={currency_code}
              investmentAmount={amount}
              raiseGoal={raise_goal}
            />
          )
        })}
    </div>
  )
}

export { WatchlistPortfolio }
