import React from 'react'
import SectionHeader from './section-header'
import { Span } from '../../lib/components'
import { InvestmentCard } from './investment-card'

interface InvestmentProps {
  opportunity_title: string
  cover_image: string
  values: { date: string; return: number; investment?: number }[]
}

interface Props {
  averageReturn: string
  currentInvestmentValue: string
  investments: InvestmentProps[]
}

const ActiveInvestments = ({ averageReturn, currentInvestmentValue, investments }: Props) => {
  return (
    <div className='dashboard-container__active-investments-section'>
      <SectionHeader
        sectionName='Active Investments'
        sectionHelperValues={[
          { name: 'Avg Return', value: averageReturn },
          { name: 'Current Investment Value*', value: currentInvestmentValue },
        ]}
      />
      <Span text='' variant='thin'>
        You currently have <Span text={`${investments.length} active`} variant='bold' /> investments
      </Span>

      <div className='dashboard-container__active-investments-section__investments-cards-section'>
        {investments.length !== 0 &&
          investments.map((investment: InvestmentProps, idx: number) => {
            return <InvestmentCard key={idx} {...investment} />
          })}
      </div>
    </div>
  )
}

export { ActiveInvestments }
